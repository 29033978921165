import { Fragment, useState, useEffect } from 'react';
import { useParams ,useHistory} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, Loader, Animate, useAPI, Event } from 'components/lib';

import '../../css/run.css';

export function Freewait(props){

  const [state, setState] = useState({});
  //const data = useAPI('/api/queue');
  const { id } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState('');
  const [error, setError] = useState(false);

  const loader = document.querySelector('.loader');
  const progressBar = document.querySelector('.progress-bar');

  
  

  const base = 'https://beta.checkops.com';


  useEffect(() => {
    const loaderContainer = document.getElementById('loader-container');
    const progressBar = document.getElementById('progress-bar');
  
    function startLoader() {
      let progress = 0;
      const intervalId = setInterval(function () {
        progress = progress + 1;
        progressBar.style.width = `${progress}%`;
  
        if (progress === 100) {
          clearInterval(intervalId);
          loaderContainer.style.display = 'none';
        }
      }, 600); // 1 minute divided into 100 steps
    }
  
    startLoader();
  }, []);

  

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // Call API 2
        const response = await fetch(`${base}/api/runs/${id}`);
        if (!response.ok) {
          throw new Error('API 2 request failed');
        }
  
        const data = await response.json();
        if (data.status && data.status.statusCode === 'complete') {
          clearInterval(interval);
  
          // Navigate to the result page
          navigate(`/freerun/${id}`);
        } 
      } catch (error) {
        console.error('Error:', error);
        // Handle error case
        clearInterval(interval);
        // Show error message to rerun the test or contact support
        setError(true);
      }
    }, 5000);
  
    return () => {
      clearInterval(interval);
    };
  }, [id, navigate]);

  // Render the error message
if (error) {
  return <p>Oops! Something went wrong. Please try again frm run page or contact support at support@monkeytest.ai.</p>;
}
  
  return(

<div id="loader-container" class="loader-container">
      <div class="loader-text">Our monkeys are checking now...</div>
      <img src="https://www.animoticons.com/files/japanese-emoticons/yoyo-monkey-smiley-faces/26.gif" class="loader-logo" alt="Loader" width="80" />
      <div class="progress-bar-container">
        <div id="progress-bar" class="progress-bar"></div>
      </div>
    </div>

  );
}


